/* html { filter: grayscale(100%); } */
body,input,h1,h2,h3,h4,h5,h6,p,textarea,table,div,ul,li,select,td,th,em,span,i,button,code{margin:0;padding:0;color:inherit;line-height: inherit;font-family: 'PingFang SC','PingFangMedium',"microsoft yahei"; }
.body {background:url(../images/idx_01.jpg) no-repeat center top #f8f8f8;background-size:100% auto;}
img{border:0;display: inline-block;max-width:100%;vertical-align: middle;height: auto;}
.fl{float:left;}
.fr{float:right;}
ul,li{ list-style:none; }
a,p,span,i,em{ text-decoration:none; color:inherit;font-size: inherit;}
pre{word-break: break-word;}
:focus{outline:none!important;border-color:inherit;}
.clearfix{*zoom: 1;}
.clearfix:before,.clearfix:after
{display: table;line-height: 0;content: "";}
.clearfix:after{clear: both;}
table{border-collapse:collapse;border-spacing:0;width:100%}
h3,h4,h5,h6{font-weight: normal;}
em,i{font-style:normal;}
*{box-sizing:border-box;color: #333;}
input[type="button"], input[type="submit"], input[type="reset"],input[type="text"] {-webkit-appearance: none;}
input[type="button"]{border:none;}
textarea {  -webkit-appearance: none;}  
.auto{margin:0 auto;max-width: 1200px; width: 96%;}
:hover{transition: all .2s;}
.img_hover_wrap{overflow: hidden;}
.img_hover{transition: all .3s;}
.img_hover:hover{transform: scale(1.05,1.05);}
.img_traslate{transition: all .3s;}
.img_traslate:hover{transform: translateY(-10px);}
.img_rotate{transition: all .3s;}
.img_rotate:hover{transform:rotate(-360deg);}
.bold{font-weight: bold;}
.normal{font-weight: normal!important;}
.center{text-align: center!important;}
.img{/* width: 100%; */display: block;    height: auto;}
.ellipsis{overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.ele{position:relative;}
.ele:before,.ele:after{content:'';display: inline-block;vertical-align: middle;}
.ipt{display: block;border:none;}
.ipt_button{cursor: pointer;}
.item_scale .icon{transition: all .3s;}
.item_scale:hover .icon{transform: scale(1.05,1.05);}
.text-left{text-align: left!important;}
.text-right{text-align: right!important;}
.clamp{overflow: hidden;text-overflow: ellipsis;display: -webkit-box!important;display: -ms-flexbox; -webkit-box-orient: vertical;/*-webkit-line-clamp: 3;*/}
.clamp1{overflow: hidden;text-overflow: ellipsis;display: -webkit-box!important;display: -ms-flexbox; -webkit-box-orient: vertical;-webkit-line-clamp: 1;}
.clamp2{overflow: hidden;text-overflow: ellipsis;display: -webkit-box!important;display: -ms-flexbox; -webkit-box-orient: vertical;-webkit-line-clamp: 2;}
.clamp3{overflow: hidden;text-overflow: ellipsis;display: -webkit-box!important;display: -ms-flexbox; -webkit-box-orient: vertical;-webkit-line-clamp: 3;}
body{background: #f8f8f8;overflow-x: hidden;}

.no-list-data { text-align: center; font-size: 0.8em; color: #666;}
.mq-img { height: 120px; padding: 3px; border: 1px dashed #7c7c7c; border-radius: 2px; margin: 10px; background-color: #fff; }

.item{}
.item .imgbox{display: block;overflow: hidden;}
.item .img{transition: all 1s;width: 100%;display: block;}
.item:hover .imgbox .img{transform: scale(1.1,1.1);}

.zoomimg{position:relative;overflow: hidden;height: 0;display: block;}
.zoomimg .img{display: block;position:absolute;width: 100%;height: 100%;left: 0;top: 0;}
.list-detail { margin-top: 20px; }

.flipx .icon{transition: all .3s;}
.flipx:hover .icon{transform:scaleX(-1);}
.scale .icon{transition: all .3s;}
.scale:hover .icon{transform: scale(1.1,1.1);}

.underline:hover{text-decoration: underline;}
label{-webkit-tap-highlight-color: rgba(255,0,0,0);} 

.flex{display: -webkit-box; display: -moz-box;display: -ms-flexbox; display: -webkit-flex; display: flex;}
.flex-align-start{align-items: flex-start;}
.flex-align-center {-webkit-box-align: center;-webkit-align-items: center;-ms-flex-align: center;align-items: center;}
.flex-align-end{align-items: flex-end;}
.flex-pack-center {-webkit-box-pack: center;-webkit-justify-content: center;-ms-flex-pack: center;justify-content: center;}
.flex-pack-justify {-webkit-box-pack: justify;-webkit-justify-content: pace-between;-ms-flex-pack: justify;justify-content: space-between;}
.flex-pack-start{justify-content: flex-start;}
.flex-pack-end{justify-content: flex-end;}
.flex-v {-webkit-box-orient: vertical; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; }
.flex-w{flex-wrap:wrap;}
.flex-1{flex:1;overflow: hidden;}
.flex-11{flex:1;}


.flow-btn {opacity:0.5; position: absolute; z-index: 10; color: red; top: 48%; display: inline-block; width:50px;height: 40px; padding: 3px; cursor:pointer; }
.flow-left { left: 0px; background:url(../images/right.svg) no-repeat center; background-size:contain;transform: rotate(180deg);}
.flow-right { right: 0px; text-align: right; background:url(../images/right.svg) no-repeat center; background-size:contain;}
.flow-btn:hover {opacity: 1}
.flow-border {border: 2px solid #dd0722}
.flow-progress {opacity:0.8; position: absolute; z-index: 10; color: red; right: 5px; top: 5px;}

.c-red{color:#dd0722!important;}

.more{color:#dd0722;float: right; font-size: 0.8em}
.mg-b-10 {margin-bottom: 10px;}
.out-link-txt {display: inline-block;background-color:#f3f3f3;color: #666;padding: 20px 40px; border-radius: 5px; margin-bottom: 20px; box-shadow: 3px 2px 5px #d9d9d9;}
.out-link {display: inline-block;background-color:#dd0722;color: #fff;padding: 10px 40px; border-radius: 5px; box-shadow: 3px 2px 5px #d9d9d9; }

/* banner */
.youth-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
	color: #fff;
	font-size: 40px;
	border-radius: 5px;
	background: url(../images/youth_banner.png) no-repeat;
	margin-top: 16px;
}

/* index */
.hd-t{height: 50px;line-height: 50px;font-size: 14px;color:#dd0722;}
.hd-t .line{margin:0 20px;width: 1px;height: 14px;background-color: #ddd;display: inline-block;vertical-align: middle;}
.hd-t .ico{width: 16px;display: inline-block;vertical-align: middle;margin-right: 8px;margin-top: -2px;}
.hd-t .lk:hover{text-decoration: underline;}

.hd-c{margin-top: 20px;}
.hd-logo{}
.hd-logo .img{ height: 100px; }

.hd-sear{border-radius:5px;background-color: #fff;overflow: hidden;width:360px;margin-top: 15px;display: block;}
.hd-sear .ipt-txt{height: 50px;line-height: 50px;width:calc(100% - 50px);float: left;font-size: 14px;color:#333;padding:0 20px;border:1px solid #dd0722;border-right:none;border-radius:5px 0 0 5px;}
.hd-sear .submit{width: 50px;height: 50px;background:url(../images/icon_03.png) no-repeat center #dd0722;background-size:20px;float:right;border:none;cursor: pointer;}
.hd-sear .submit:hover{background-color: #fff;background-color: #ffb300;}

.hd-nav {font-size: 1em; margin-bottom: 20px; background-color: #dd0722; margin-top: 20px; height: 55px;}
.hd-nav li {display: inline-block;vertical-align: middle;text-align: center;width: 16.66666%; height: 55px; position: relative;}
.hd-nav li:hover .sub-menu { display: inline-block; }
.hd-nav li>a {display: block;font-size: 18px;color:#fff;line-height: 55px;}
.hd-nav li.cur>a{background-color: #ffb300;box-shadow:0 3px 13px rgba(234,242,0,.44);}
.hd-nav li .ico{width: 20px;height: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;}
.hd-nav li .ico1{background:url(../images/nav_01.png) no-repeat center;background-size:contain;}
.hd-nav li .ico2{background:url(../images/nav_02.png) no-repeat center;background-size:contain;}
.hd-nav li .ico3{background:url(../images/nav_03.png) no-repeat center;background-size:contain;}
.hd-nav li .ico4{background:url(../images/nav_04.png) no-repeat center;background-size:contain;}
.hd-nav li .ico5{background:url(../images/nav_05.png) no-repeat center;background-size:contain;}

.sub-menu { display: none; color: #fff; background-color: #dd0722; width: 100%; z-index: 100; position: relative;}
.sub-menu div { font-size: 0.9em; padding: 15px; cursor: pointer; border-bottom: 1px solid #d5060b; }
.sub-menu div:hover {background-color: #ffb300;box-shadow:0 5px 13px rgba(234,242,0,.44);}

.idx-ad{display: flex;justify-content: space-around;flex-wrap: wrap;}
.idx-ad .lk{display: block; width: 100%; margin-bottom: 20px;}
.idx-ad .lk1{width: 100%;}
.idx-ad .lk2{width: calc(50% - 10px);}
.idx-ad .lk3{width: calc(33.3333% - 10px);}

.m{background-color: #fff;margin-bottom: 20px;padding: 20px;}

.idx-menu{border-bottom:1px solid #efefef;margin-bottom: 20px;}
.idx-menu .box{font-size: 0;}
.idx-menu .lk{font-size: 18px;color:#000000;line-height: 44px;display: inline-block;vertical-align: middle;text-align: center;cursor: pointer;position:relative;}
.idx-menu .lk::after{content:'';position:absolute;right: 0;top: 50%;width: 1px;height: 20px;background-color: #efefef;margin-top: -10px;}
.idx-menu .lk.cur{color:#fff;background-color: #dd0722;box-shadow:0 3px 13px rgba(177,60,60,.44);}
.idx-menu .lk.cur::after{display: none;}
.idx-menu .lk:hover{color:#dd0722;}
.idx-menu .lk.cur:hover{color:#fff;}
.idx-menu .lk:last-child::after{display: none;}

.idx-tt{margin-bottom: 20px;}
.idx-tt .bt{min-width:120px;display: inline-block;vertical-align: middle;font-size: 18px;color:#fff;line-height: 44px;text-align: center;box-shadow:0 3px 13px rgba(177,60,60,.44);background-color: #dd0722;padding:0 15px;}

.idx-mod {}
.idx-mod .hot-pic {padding-bottom: 20px;border-bottom:0px solid #efefef; margin-bottom: 20px; display: flex; justify-content: space-around; }
.idx-mod .hot-pic .imgbox{width: 45%;height: 160px;display: block;float: left;}
.idx-mod .hot-pic .img{width: 100%;height: 100%;object-fit: cover;display: block;}
.idx-mod .hot-pic .info{margin-left: 250px;}
.idx-mod .hot-pic .title{margin-top: 5px; font-size: 18px;color:#000000;font-weight: bold;display: block;}
.idx-mod .hot-pic .txt{font-size: 14px;color:#000000;line-height: 26px;-webkit-line-clamp: 5;height: 130px;overflow: hidden;margin-top: 8px;}
.idx-mod .hot-pic .more{color:#dd0722;float: right;}
.idx-mod .hot-pic .more:hover{text-decoration: underline;}
.idx-mod .hot-pic .imgbox:hover .title{color:#dd0722;}

.all-line-pic {padding-bottom: 20px;border-bottom:0px solid #efefef; margin-bottom: 20px; display: flex; justify-content: space-around; }
.all-line-pic .imgbox{width: 22%;height: 160px;display: block;float: left;}
.all-line-pic .img{width: 100%;height: 100%;object-fit: cover;display: block;}
.all-line-pic .info{margin-left: 250px;}
.all-line-pic .title{margin-top: 5px; font-size: 18px;color:#dd0722;font-weight: bold;display: block;}

.idx-mod .hot-box { padding-bottom: 20px;border-bottom:0px solid #efefef;margin-bottom: 10px;}
.idx-mod .hot-box .imgbox{width: 45%;height: 160px;display: block;float: left;}
.idx-mod .hot-box .img{width: 100%;height: 100%;object-fit: cover;display: block;}
.idx-mod .hot-box .date{text-align: center;width: 75px;text-align: center;float: left;padding-top: 3px;}
.idx-mod .hot-box .day{font-size: 24px;color:#000000;font-weight: bold;line-height: 1;}
.idx-mod .hot-box .year{font-size: 16px;color:#999999;line-height: 1;margin-top: 5px;}
.idx-mod .hot-box .info{margin-left: 95px;}
.idx-mod .hot-box .title{display: block;font-size: 18px;color:#dd0722;font-weight: bold;}
.idx-mod .hot-box .txt{font-size: 14px;color:#000000;margin-top: 4px;}
.idx-mod .hot-box .imgbox:hover .title{text-decoration: underline;}

.idx-mod .list{ border-top: 1px solid #efefef; padding-top: 5px; }
.idx-mod .list .item{line-height: 34px;}
.idx-mod .list .title{font-size: 14px;color:#000000;display: inline-block;width:calc(100% - 110px);vertical-align: middle;}
.idx-mod .list .title:before{content:'';display: inline-block;vertical-align: middle;width: 6px;height: 6px;background-color: #dd0722;border-radius:50%;margin-right: 20px;}
.idx-mod .list .date{font-size: 14px;color:#999999;float:right;}
.idx-mod .list .item:hover .title{color:#dd0722;}

.idx-main{padding-bottom: 30px;}
.idx-slide{width: 550px;}
.idx-slide .view-list{position:relative;}
.idx-slide .view-list .item{position:relative;}
.idx-slide .view-list .imgbox{display: block;height: 340px;}
.idx-slide .view-list .img{object-fit: cover;width: 100%;height: 100%;display: block;}
.idx-slide .view-list .title{font-size: 18px;color:#fff;line-height: 60px;background-color: rgba(0,0,0,.5);position:absolute;width: 100%;left: 0;bottom: 0;padding:0 20px;}

.idx-slide .thumb-list{margin-top: 20px;font-size: 0;white-space: nowrap;height: 95px;overflow: hidden;width:calc(100% + 10px);margin-left: -5px;}
.idx-slide .thumb-list .item{/* width: 95px!important; */height: 95px;display: inline-block;vertical-align: middle;border:1px solid #fff;overflow:hidden;}
.idx-slide .thumb-list .smimg{object-fit: cover; width: 100%;height: 100%; display: block;}

.idx-slide .thumb-list .slick-current .item{border-color:#dd0722;}
.idx-slide .thumb-list .slick-slide{padding: 0 5px;}

.idx-tout{width:calc(100% - 570px);}
.idx-tout .idx-menu .lk{width: 25%;}

.idx-marquee{}
.idx-marquee .detail{position:relative; height: 80px; overflow: hidden}
.idx-marquee .list{left: 50px; white-space: nowrap;overflow: hidden; height: 80px; padding-left: 20px; position: absolute}
.idx-marquee .list .item{display: inline-block;vertical-align: middle; margin-right: 20px;border:1px solid #efefef;width: auto!important;border-radius:10px; overflow: hidden;}
.idx-marquee .list .img{height: 78px;width: auto;display: block;}

.idx-marquee .bd{padding:0 40px;}
.idx-marquee .bd .tempWrap{width: 100%!important;}

.idx-marquee .anniu{width: 40px;height: 80px;background:url(../images/idx_13.png) no-repeat center #dd0722;background-size:cover;border-radius:10px;position:absolute;top: 0;cursor: pointer;z-index:60;}
.idx-marquee .prev{transform: rotate(180deg);cursor: pointer;}
.idx-marquee .next{right: 0;}

.idx-s3{ margin-top: 20px }
.idx-s3 .idx-menu .lk{width: 25%;}
.idx-s3 .col{width: 48.5%;}

.idx-s4 .col{width: 48.5%;}

.foot{padding:20px 0;background-color: #dd0722;}
.foot .t-tit{font-size: 22px;color:#fff;font-weight: bold;margin-bottom: 20px;}
.foot .list{margin-left: -1.5%;}
.foot .list .item{width: 23%; margin-left: 1.5%; float: left;}
.foot .list .select{font-size: 16px;color:#fff;height: 50px;line-height: 50px;padding:0 15px;appearance: none;-webkit-appearance: none;background:url(../images/ft_01.png) no-repeat 95% center rgba(255,255,255,.1);background-size:10px;border:none;width: 100%;}
.foot .txt{text-align: center;text-align: center;font-size: 14px;line-height: 26px;margin-top: 30px;color:rgba(255,255,255,.5);}
.foot .txt span{margin:0 10px;}
.foot .txt a:hover{color:#fff;}

.side-ewm{position:fixed;left: 0; background-color: #fff;width: 100px;bottom: 10%;z-index:100; font-size: 0.8em}
.side-ewm .item{text-align: center;padding:25px 0;border-bottom:1px solid #efefef;cursor: pointer;}
.side-ewm .fz{font-size: 12px;color:#000000;line-height: 18px;}
.side-ewm .pic{width: 80%;max-width:140px;display: none;margin:0 auto;margin-top: 5px;}
.side-ewm .item:last-child{border-bottom:none;}
.side-ewm .item.cur{background:url(../images/idx_17.jpg) no-repeat center;background-size:cover;}
.side-ewm .item.cur .fz{color:#fff;}
.side-ewm .item.cur .pic{display: block;}

/* 专题 */
.main{margin-bottom: 50px;padding: 20px 20px 80px;background-color: #fff;}
.main .wrap{overflow: hidden;}
.main .wrap-l{width: 240px;}
.main .wrap-r{margin-left: 300px;}

.top-bar{padding-bottom: 2px;border-bottom:1px solid #dd0722;margin-bottom: 10px;}
.top-bar .bt{font-size: 16px;color:#fff;display: inline-block;vertical-align: middle;min-width:100px;line-height: 44px;height: 44px;padding: 0 20px;position:relative;background-color: #dd0722;}
.top-bar .bt::after{content:'';position:absolute;width: 22px;height: 44px;background:url(../images/icon_06.png) no-repeat center;background-size:100% 100%;top: 0;right: -22px;}
.crumb{font-size: 14px;color:#333333;line-height: 44px;}
.crumb a:hover{color:#dd0722;}

.side-menu{background-color: #fafbfc;padding-bottom: 10px;}
.side-menu .t-tit{font-size: 24px;color:#fff;line-height: 70px;padding-left: 20px;margin-bottom: 10px;background:url(../images/img_02.jpg) no-repeat center;background-size:100% 100%;}
.side-menu .t-tit .icon{width: 28px;display: inline-block;vertical-align: middle;margin-right: 10px;}

.menu-list{}
.menu-list li{margin-bottom: 2px; position: relative;}
.menu-list li>a{display: block;font-size: 16px;clor:#333333;line-height: 50px;padding-left: 20px;position:relative;}
.menu-list li>a:before{content:'';position:absolute;left: 0;top: 0;width: 4px;transition: all .3s;height: 0;background-color: #dd0722;}
/* .menu-list li>a::after{content:'';position:absolute;right: 20px;top: 50%;transform: translate(0,-50%);width: 12px;height: 12px;background:url(../images/icon_05.png) no-repeat center;background-size:contain;} */
.menu-list li.cur>a,.menu-list li:hover>a{color:#dd0722;background-color: #f7e2e6;}
.menu-list li.cur>a:before,.menu-list li:hover>a:before{height: 100%;}
.menu-list li.cur>a::after,.menu-list li:hover>a::after{background-image:url(../images/icon_05-1.png)}

.sub-active {border-left: 3px solid #dd0722; color:#dd0722;background-color: #f7e2e6; }
/* .sub-a-active::after {content:'';position:absolute;right: 20px;top: 50%;transform: translate(0,-50%);width: 12px;height: 12px;background:url(../images/icon_05-1.png) no-repeat center;background-size:contain;} */

.menu-list li:hover .sub-menu-item{display: block;}
.sub-menu-item {margin-left: 40px; display: block; background-color: #fafbfc ; }
.sub-menu-item div > a { display: block; position: relative; font-size: 16px;clor:#333333;line-height: 50px;padding-left: 20px;position:relative; }
.sub-menu-item div > a::after{content:'';position:absolute;right: 20px;top: 50%;transform: translate(0,-50%);width: 12px;height: 12px;background:url(../images/icon_05.png) no-repeat center;background-size:contain;}
.sub-menu-item div > .sub-a-active::after {background-image:url(../images/icon_05-1.png)}
.sub-menu-item div > a:hover {border-left: 3px solid #dd0722; color:#dd0722;background-color: #f7e2e6; }
.sub-menu-item div:hover > a::after {background-image:url(../images/icon_05-1.png)}


.zt-list{margin-left: -3%;}
.zt-list .item{width: 47%;float: left;margin:0 0 3% 3%;}
.zt-list .imgbox{display: block;height: 210px; border: 1px solid #eee;}
.zt-list .img{width: 100%;height: 100%;object-fit: cover;}
.zt-list .info{padding:15px 15px 20px;border:1px solid #eceff2;border-top:none;}
.zt-list .title{font-size: 18px;color:#333333;display: block;font-weight: bold;}
.zt-list .txt{font-size: 16px;color:#666666;line-height: 26px;-webkit-line-clamp: 3;overflow: hidden;height: 78px;margin-top: 10px;}
.zt-list .more{font-size: 14px;color:#fff;width: 96px;line-height: 34px;display: block;margin:34px auto 0;background-color: #dd0823;text-align: center;}
.zt-list .more:hover{background-color: #ffb300;}
.zt-list .item:hover .title{color:#dd0823;}

.pagelist{margin-top: 60px;text-align: center;}
.pagelist .total{font-size: 14px;color:#666666;display: inline-block;vertical-align: middle;}
.pagelist .pages{display: inline-block;vertical-align: middle;}
.pagelist .last,.pagelist .next,.pagelist .prv,.pagelist .first{display: inline-block;vertical-align: middle;padding:0 14px;font-size: 14px;color:#999999;line-height: 38px;height: 38px;background-color: #f6f7f9;margin:0 5px;cursor: pointer;border-radius:4px;}
.pagingUl{display: flex;display: inline-block;vertical-align: middle;}
.pagingUl li{font-size: 14px;color:#666666;width: 38px;height:38px;line-height: 38px;text-align: center;background-color: #f6f7f9;margin:0 5px;display: inline-block;vertical-align: middle;border-radius:4px;overflow: hidden;}
.pagingUl li a{width: 100%;height: 100%;display: block;}
.pagelist .active,.pagingUl li a:hover{color:#fff;background-color: #dd0722;}
.pagelist .last:hover,.pagelist .next:hover,.pagelist .prv:hover,.pagelist .first:hover{color:#fff;background-color: #dd0722;}

/* 新闻中心 */
.xw-list{}
.xw-list .item{border-bottom:1px dashed #dbdbdb;line-height: 46px;}
.xw-list .title{font-size: 14px;color:#333333;display: inline-block;vertical-align: middle;width:calc(100% - 160px);}
.xw-list .title:before{content:'';display: inline-block;vertical-align: middle;width: 6px;height: 6px;background-color: #dd0722;border-radius:50%;margin-right: 15px;}
.xw-list .date{font-size: 14px;color:#999999;float:right;}
.xw-list .item:hover .title{color:#dd0722;}

/* 新闻详情 */
.det-sec{margin-top: 30px; background-color: #fff; padding: 20px; min-height: 800px}
.det-sec .title{font-size: 26px;color:#000000;text-align: center;font-weight: bold;margin-bottom: 10px;}
.det-sec .sm{text-align: center;}
.det-sec .sm span{line-height: 20px; padding:5px 0px; font-size: 14px;color:#999999;display: inline-block;vertical-align: middle;margin:0 20px;}
.det-sec .sm .ico{width: 20px;display: inline-block;vertical-align: middle;margin-right: 5px;}
.det-sec .editbox{font-size: 14px;color:#000000;line-height: 26px;margin-top: 25px; font: 400 1.0em/1.9 PingFang SC,Lantinghei SC,Microsoft Yahei,Hiragino Sans GB,Microsoft Sans Serif,WenQuanYi Micro Hei,sans-serif}
.det-sec .editbox > img { margin: auto }
figure.image { margin: auto; text-align: center; padding: 20px; }

@media screen and (min-width:1200px) and (max-width:1440px){
	.auto{width: 1100px;}
	
	.side-ewm{width: 150px; border: 1px solid #eee}
	.side-ewm .item{padding:15px 0;}
	.side-ewm .fz{font-size: 13px;line-height: 22px;}
	.side-ewm .pic{width: 60%;}
	
}

@media screen and (min-width:992px) and (max-width:1199px){
	.auto{width: 100%;padding:0 2%;}
	.side-ewm{display: none;}
	.hd-nav{margin-top: 40px;}
	
	/* index */
	.idx-menu .lk{font-size: 17px;}
	.idx-slide{width: 480px;}
	.idx-tout{width:calc(100% - 500px);}

	/* 专题 */
	.main{padding-bottom: 40px;}
	.main .wrap-r{margin-left: 270px;}
}

@media screen and (min-width:768px) and (max-width:991px){
	.sj-menu{display: block;}
	
	.auto{width: 100%;padding:0 2%;}
	.sj-menu{position:absolute;right: 3%;top: 50%;width: 40px;height: 40px;background: url(../images/menu.png) no-repeat center;background-size:30px;margin-top: -20px;display: block;}
	.sj-menu.close{background: url(../images/close.png) no-repeat center;background-size:contain;}
	
	.head{background:#fff;padding:10px 0;position:fixed;width: 100%;left: 0;top: 0;z-index:100;border-bottom:1px solid #efefef;}
	.head+div{margin-top: 80px;}
	.hd-t{display: none;}
	.hd-c{margin-top: 0;}
	
	.hd-logo .img{height: 60px;}
	
	.hd-sear{display: none;}
	
	.hd-nav{margin-top: 0;position:fixed;width: 100%;height: 100%;left: 0;top: 80px;display: none;}
	.hd-nav li{display: block;width: 100%;text-align: left;border-bottom:1px solid rgba(255,255,255,.2);}
	.hd-nav li .ico{display: none;}
	.hd-nav li>a{padding:0 3%;line-height: 70px;}
	.hd-nav li.cur>a, .hd-nav li:hover>a{background-color: transparent;box-shadow:none;}

	/* index */
	body{background: #f8f8f8!important;}
	.idx-main{padding-bottom: 10px;padding-top:0;}
	.idx-main .auto{padding: 0;}
	.idx-menu .lk{font-size: 17px;}
	
	.idx-mod .list .title{font-size: 15px;}
	.m{padding: 2%;margin-bottom: 15px;}
	
	.idx-tt .bt{font-size: 17px;}
	
	.idx-ad{padding:0;background-color: #fff;}
	.idx-ad .lk{margin-bottom: 15px;}
	.idx-ad .lk2, .idx-ad .lk3{width:calc(50% - 7px);}
	
	.idx-slide{float:none;width: 100%;}
	.idx-slide .thumb-list{display: none;}
	.idx-slide .view-list .title{font-size: 16px;line-height: 50px;}
	.idx-slide .slick-dots{position:absolute;width: 100%;left: 0;bottom: 60px;text-align: right;padding:0 20px;}
	.idx-slide .slick-dots li{display: inline-block;vertical-align: middle;width: 10px;height: 10px;background-color: #fff;border-radius:50%;margin:0 3px;opacity: .6;}
	.idx-slide .slick-dots button{display: none;}
	.idx-slide .slick-dots li.slick-active{opacity: 1;}
	
	
	.idx-tout{float:none;width: 100%;margin-top: 20px;}
	.idx-s3 .col{width: 100%;float:none;margin-bottom: 20px;}
	.idx-s3 .col:last-child{margin-bottom: 0;}
	.idx-s4 .col{width: 100%;float:none;margin-bottom: 20px;}
	.idx-s4 .col:last-child{margin-bottom: 0;}
	
	.foot .t-tit{font-size: 20px;}
	.foot .list{margin-left: -2%;}
	.foot .list .item{width: 31.3%;margin:0 0 2% 2%;}
	.foot .list .select{font-size: 14px;padding:0 15px;height: 44px;line-height: 44px;}
	
	.foot .txt{line-height: 22px;margin-top: 25px;}
	
	.side-ewm{display: none;}

	/* 专题 */
	.main{padding-bottom: 40px;margin-bottom: 0;}
	.main .wrap-l{width: 100%;float:none;}
	.main .wrap-r{margin-left: 0;}
	
	.side-menu{overflow-x: scroll;}
	.side-menu::-webkit-scrollbar{ display: none;} 
	.side-menu .t-tit{display: none;}
	.menu-list{font-size: 0;white-space: nowrap;}
	.menu-list li{display: inline-block;vertical-align: middle;}
	.menu-list li>a{padding:0 20px;}
	.menu-list li>a::after,.menu-list li>a::before{display: none;}
	
	.zt-list .imgbox{height: 180px;}
	.zt-list .title{font-size: 16px;}
	.zt-list .txt{font-size: 15px;line-height: 22px;height: 66px;}
	.zt-list .more{margin-top: 25px;}
	
	.pagelist{margin-top: 30px;}

	/* 新闻详情 */
	.det-sec .title{font-size: 22px;}
	.det-sec .sm span{margin:0 10px;}
	.det-sec .sm .ico{width: 16px;}
	.det-sec .editbox{font-size: 15px;line-height: 26px;}
}

@media screen and (min-width:320px) and (max-width:767px){
	.sj-menu{display: block;}
	
	.auto{width: 100%;padding:0 3%;}
	.sj-menu{position:absolute;right: 3%;top: 50%;width: 40px;height: 40px;background: url(../images/menu.png) no-repeat center;background-size:26px;margin-top: -20px;display: block;}
	.sj-menu.close{background: url(../images/close.png) no-repeat center;background-size:contain;}
	
	.head{background:#fff;padding:10px 0;position:fixed;width: 100%;left: 0;top: 0;z-index:100;border-bottom:1px solid #efefef;}
	.head+div{margin-top: 65px;}
	.hd-t{display: none;}
	.hd-c{margin-top: 0;}
	.hd-logo .img{height: 45px;}
	.hd-sear{display: none;}
	
	.hd-nav{margin-top: 0;position:fixed;width: 100%;height: 100%;left: 0;top: 65px;display: none;}
	.hd-nav li{display: block;width: 100%;text-align: left;border-bottom:1px solid rgba(255,255,255,.2);}
	.hd-nav li .ico{display: none;}
	.hd-nav li>a{padding:0 6%;line-height: 60px;font-size: 16px;}
	.hd-nav li.cur>a, .hd-nav li:hover>a{background-color: transparent;box-shadow:none;}
	
	
	/* index */
	body{background: #f8f8f8!important;}
	.idx-main{padding-bottom: 10px;padding-top:0;}
	.idx-main .auto{padding: 0;}
	
	.idx-menu{overflow-x: scroll;}
	.idx-menu .box{white-space: nowrap;}
	.idx-menu .lk{font-size: 16px;line-height: 32px;width: auto!important;padding:0 15px;}
	.idx-menu::-webkit-scrollbar{ display: none;} 
	
	.idx-mod .hot-box{display: flex;align-items: center;padding-bottom: 15px;}
	.idx-mod .hot-box .date{width: 60px;margin-right: 15px;}
	.idx-mod .hot-box .day{font-size: 20px;}
	.idx-mod .hot-box .year{font-size: 13px;}
	.idx-mod .hot-box .info{margin-left: 0;flex:1;overflow: hidden;}
	.idx-mod .hot-box .title{font-size: 16px;}
	
	.idx-mod .hot-pic{padding-bottom: 15px;display: flex;}
	.idx-mod .hot-pic .imgbox{width: 140px;margin-right: 15px;height: 100px;}
	.idx-mod .hot-pic .info{margin-left: 0;flex:1;overflow: hidden;}
	.idx-mod .hot-pic .title{font-size: 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box!important;display: -ms-flexbox; -webkit-box-orient: vertical;-webkit-line-clamp: 2;white-space:normal;}
	.idx-mod .hot-pic .txt{-webkit-line-clamp: 2;line-height: 22px;height: 44px;}
	
	.idx-mod .list .item{line-height: 28px;margin-bottom: 0;padding:10px 0;border-bottom:1px dashed #efefef;}
	.idx-mod .list .title{font-size: 15px;width: 100%;display: block;line-height: 28px;}
	.idx-mod .list .title:before{margin-right: 10px;width: 5px;height: 5px;}
	.idx-mod .list .date{font-size: 12px;text-align: right;float:none;display: block;line-height: 22px;}
	.idx-mod .list .item:last-child{border-bottom:none;}
	
	.m{padding: 3%;margin-bottom: 3%;}

	.idx-tt .bt{font-size: 16px;line-height: 32px;min-width:100px;}
	.idx-ad{padding:0;background-color: #fff;}
	.idx-ad .lk{margin-bottom: 8px;}
	.idx-ad .lk2, .idx-ad .lk3{width:calc(50% - 4px);}

	.idx-slide{float:none;width: 100%;}
	.idx-slide .thumb-list{display: none;}
	.idx-slide .view-list .imgbox{height: 230px;}
	.idx-slide .view-list .title{font-size: 14px;line-height: 34px;padding:0 15px;}
	.idx-slide .slick-dots{position:absolute;width: 100%;left: 0;bottom: 40px;text-align: right;padding:0 10px;}
	.idx-slide .slick-dots li{display: inline-block;vertical-align: middle;width: 6px;height: 6px;background-color: #fff;border-radius:50%;margin:0 2px;opacity: .6;}
	.idx-slide .slick-dots button{display: none;}
	.idx-slide .slick-dots li.slick-active{opacity: 1;}
	
	
	.idx-tout{float:none;width: 100%;margin-top: 20px;}
	
	.idx-marquee .list{height: 62px;}
	.idx-marquee .list .item{margin-right: 10px;}
	.idx-marquee .list .img{height: 60px;}
	.idx-marquee .anniu{height: 60px;width: 30px;border-radius:6px;}
	.idx-marquee .bd{padding:0 30px;}
	
	.idx-s3 .col{width: 100%;float:none;margin-bottom: 20px;}
	.idx-s3 .col:last-child{margin-bottom: 0;}
	.idx-s4 .col{width: 100%;float:none;margin-bottom: 20px;}
	.idx-s4 .col:last-child{margin-bottom: 0;}
	
	.foot .t-tit{font-size: 18px;margin-bottom: 10px;}
	.foot .list{margin-left: -2%;}
	.foot .list .item{width: 48%;margin:0 0 2% 2%;}
	.foot .list .select{font-size: 13px;padding:0 15px;height: 36px;line-height: 36px;background-size: 8px;}
	
	.foot .txt{line-height: 22px;margin-top: 20px;font-size: 12px;}
	.foot .txt span{display: block;margin:0;}
	.side-ewm{display: none;}
	
	/* 专题 */
	.main{padding-bottom: 40px;margin-bottom: 0;}
	.main .wrap-l{width: 100%;float:none;}
	.main .wrap-r{margin-left: 0;}
	
	.top-bar{padding-bottom: 0;border:none;}
	.top-bar .bt{display: none;}
	.crumb{float:none;}
	
	.side-menu{overflow-x: scroll;padding-bottom: 0;}
	.side-menu::-webkit-scrollbar{ display: none;} 
	.side-menu .t-tit{display: none;}
	
	.menu-list{font-size: 0; white-space: nowrap;}
	.menu-list li{display: inline-block;vertical-align: middle;margin-bottom: 0;}
	.menu-list li>a{padding:0 20px;line-height: 38px;}
	.menu-list li>a::after,.menu-list li>a::before{display: none;}

	.zt-list{margin-left: 0;}
	.zt-list .item{width: 100%;margin:0 0 3%;}
	.zt-list .imgbox{height: 140px;}
	.zt-list .title{font-size: 16px;}
	.zt-list .txt{font-size: 14px;line-height: 22px;height: auto;margin-top: 8px;}
	.zt-list .more{margin-top: 25px;}
	
	.pagelist{margin-top: 25px;}
	.pagelist .total{display: none;}
	.pagingUl{display: none;}
	.pagelist .last, .pagelist .next, .pagelist .prv, .pagelist .first{font-size: 13px;line-height: 32px;height: 32px;}
	
	/* 新闻中心 */
	.xw-list .item{line-height: 28px;padding:10px 0;}
	.xw-list .title{width: 100%;display: block;}
	.xw-list .title:before{width: 4px;height: 4px;margin-right: 6px;}
	.xw-list .date{font-size: 13px;float:none;display: block;text-align: right;}
	
	/* 新闻详情 */
	.det-sec .title{font-size: 18px;margin-bottom: 6px;}
	.det-sec .sm span{margin:0 5px;font-size: 13px;}
	.det-sec .sm .ico{width: 16px;}
	
	.det-sec .editbox{font-size: 14px;line-height: 24px;margin-top: 20px;}
}
